import React from "react"
import { graphql } from "gatsby"
import {
  Layout,
  useTranslate,
  Link,
} from "@solublestudio/gatsby-theme-soluble-source"
import { NotFoundPage as DSNotFoundPage } from "@solublestudio/bdeo-design-system"

export default function NotFoundPage({ data }) {
  const _ = useTranslate()

  return (
    <Layout
      navbarFixed={true}
      showFooter={false}
      customSeo={{
        title: _["404.seo.title"]?.text,
        description: _["404.seo.description"]?.text,
      }}
    >
      <div />
      <DSNotFoundPage
        title={_["404.title"].text}
        text={_["404.description"].text}
        cta={
          data.datoCmsOption?.notFoundPageLink
            ? {
                ...data.datoCmsOption.notFoundPageLink,
                Tag: Link,
              }
            : null
        }
      />
    </Layout>
  )
}

export const query = graphql`
  query NotFoundData($id: String, $language: String) {
    datoCmsOption(id: { eq: $id }, locale: { eq: $language }) {
      notFoundPageLink {
        ...Button
      }
    }
  }
`
